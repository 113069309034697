import React from 'react';

import { styled } from '@mui/material/styles';
import MuiCardHeroItem from '@mui/material/Card';
import { default as MuiCardHeroItemMedia } from '@mui/material/CardMedia';
import CardHeroItemActions from '@mui/material/CardActions';
import CardHeroItemContent from '@mui/material/CardContent';
import CardHeroItemActionArea from '@mui/material/CardActionArea';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

import sidekick from '@last-rev/contentful-sidekick-util';

import { getFirstOfArray } from '../utils/getFirstOfArray';
import ErrorBoundary from '../ErrorBoundary';
import ContentModule from '../ContentModule';
import type { CardHeroItemProps, CardHeroItemOwnerState } from './CardHeroItem.types';
import { type LinkProps } from '../Link';

const CardHeroItem = (props: CardHeroItemProps) => {
  const {
    id,
    index,
    media,
    rteOverline: overline,
    rteTitle: title,
    rteSubtitle: subtitle,
    className,
    body,
    link,
    actions,
    variant,
    loading,
    sidekickLookup,
    sizes
  } = props;

  const ownerState = {
    ...props,
    variant
  };

  // const image = getFirstOfArray(media);

  return (
    <ErrorBoundary>
      <Root
        ownerState={ownerState}
        data-testid="CardHeroItem"
        {...sidekick(sidekickLookup)}
        className={className}>
        {/* <CardHeroItemWrap ownerState={ownerState}> */}
        {!!link ? (
          <CardHeroItemLink component={CardHeroItemActionArea} {...(link as any)} variant="link" />
        ) : null}

        {media?.length || loading ? (
          // @ts-ignore: TODO
          <CardHeroItemMedia ownerState={ownerState}>
            {!loading ? (
              media?.map((image: any) => (
                <ContentModule
                  key={image.id}
                  __typename="Media"
                  {...sidekick(sidekickLookup, 'media')}
                  {...image}
                  data-testid="CardHeroItem-media"
                  sizes={'(min-width: 600px) 50vw, 90vw'}
                  priority={index == 0}
                />
              ))
            ) : (
              <Skeleton variant="rectangular" />
            )}
          </CardHeroItemMedia>
        ) : null}

        {!loading && (overline || title || subtitle || body) ? (
          // @ts-ignore: TODO
          <ContentWrap ownerState={ownerState}>
            <Box component={index == 0 ? 'h1' : 'h3'}>
              {!!overline && (
                <Overline
                  {...sidekick(sidekickLookup, 'overline')}
                  body={overline}
                  __typename="RichText"
                  data-testid="CardHeroItem-overline"
                  variant="overline"
                  component="span"
                  ownerState={ownerState}
                />
              )}{' '}
              {/* Needed to add a single space between */}
              {!!title && (
                <Title
                  {...sidekick(sidekickLookup, 'title')}
                  body={title}
                  __typename="RichText"
                  data-testid="CardHeroItem-title"
                  component="span"
                  ownerState={ownerState}
                />
              )}
            </Box>
            {!!subtitle && (
              <Subtitle
                __typename="RichText"
                body={subtitle}
                {...sidekick(sidekickLookup, 'subtitle')}
                data-testid="CardHeroItem-subtitle"
                ownerState={ownerState}
                component={index == 0 ? 'h2' : 'h4'}
              />
            )}

            {body?.json ? (
              <BodyWrap ownerState={ownerState} {...sidekick(sidekickLookup, 'body')}>
                <Body
                  __typename="RichText"
                  body={body}
                  ownerState={ownerState}
                  data-testid="CardHeroItem-body"
                />
              </BodyWrap>
            ) : null}
            {(actions?.length || loading) && (
              <ActionsWrap
                {...sidekick(sidekickLookup, 'actions')}
                data-testid="CardHeroItem-actions"
                // @ts-ignore: TODO
                ownerState={ownerState}>
                {
                  !loading
                    ? actions?.map((link: any, index: number) => (
                        <Action
                          key={`CardHeroItem-${id}-link-${link?.id || index}`}
                          {...(link as LinkProps)}
                          ownerState={ownerState}
                          size="large"
                        />
                      ))
                    : null
                  // <Skeleton variant="text" width="100%" />
                }
              </ActionsWrap>
            )}
          </ContentWrap>
        ) : null}

        {loading ? (
          <ContentWrap ownerState={ownerState} data-testid="CardHeroItem-ContentSkeleton">
            <Skeleton variant="text" width="100%" />

            <Skeleton variant="text" width="100%" />

            <Skeleton variant="text" width="100%" />

            <BodyWrap
              __typename="RichText"
              ownerState={ownerState}
              {...sidekick(sidekickLookup, 'body')}>
              <Skeleton variant="text" width="100%" height="64px" />
            </BodyWrap>
          </ContentWrap>
        ) : null}

        {/* </CardHeroItemWrap> */}
      </Root>
    </ErrorBoundary>
  );
};

const Root = styled(MuiCardHeroItem, {
  name: 'CardHeroItem',
  slot: 'Root',
  overridesResolver: (_, styles) => [styles.root]
})<{ ownerState: CardHeroItemOwnerState }>``;

const CardHeroItemWrap = styled(MuiCardHeroItem, {
  name: 'CardHeroItem',
  slot: 'CardHeroItemWrap',
  overridesResolver: (props, styles) => [styles.CardHeroItemWrap]
})<{ ownerState: CardHeroItemOwnerState }>``;

const CardHeroItemLink = styled(ContentModule, {
  name: 'CardHeroItem',
  slot: 'CardHeroItemLink',
  overridesResolver: (_, styles) => [styles.link]
})<{ ownerState: CardHeroItemOwnerState }>``;

const CardHeroItemMedia = styled(MuiCardHeroItemMedia, {
  name: 'CardHeroItem',
  slot: 'CardHeroItemMedia',
  overridesResolver: (_, styles) => [styles.CardHeroItemMedia]
})<{ ownerState: CardHeroItemOwnerState }>``;

const ActionsWrap = styled(CardHeroItemActions, {
  name: 'CardHeroItem',
  slot: 'ActionsWrap',
  overridesResolver: (_, styles) => [styles.actionsWrap]
})<{ ownerState: CardHeroItemOwnerState }>``;

const Action = styled(ContentModule, {
  name: 'CardHeroItem',
  slot: 'CardHeroItemAction',
  overridesResolver: (_, styles) => [styles.action]
})<{ ownerState: CardHeroItemOwnerState }>``;

const ContentWrap = styled(CardHeroItemContent, {
  name: 'CardHeroItem',
  slot: 'ContentWrap',
  overridesResolver: (_, styles) => [styles.contentWrap]
})<{ ownerState: CardHeroItemOwnerState }>``;

const Overline = styled(ContentModule, {
  name: 'CardHeroItem',
  slot: 'Overline',
  overridesResolver: (_, styles) => [styles.overline]
})<{ ownerState: CardHeroItemOwnerState }>``;

const Title = styled(ContentModule, {
  name: 'CardHeroItem',
  slot: 'Title',
  overridesResolver: (_, styles) => [styles.title]
})<{ ownerState: CardHeroItemOwnerState }>``;

const Subtitle = styled(ContentModule, {
  name: 'CardHeroItem',
  slot: 'Subtitle',
  overridesResolver: (_, styles) => [styles.subtitle]
})<{ ownerState: CardHeroItemOwnerState }>``;
const BodyWrap = styled(Box, {
  name: 'CardHeroItem',
  slot: 'BodyWrap',
  overridesResolver: (_, styles) => [styles.bodyWrap]
})<{ ownerState: CardHeroItemOwnerState }>``;

const Body = styled(ContentModule, {
  name: 'CardHeroItem',
  slot: 'Body',
  overridesResolver: (_, styles) => [styles.body]
})<{ ownerState: CardHeroItemOwnerState }>``;

export default CardHeroItem;
